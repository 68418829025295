.sidebar {
  display: none;
}
@media (min-width: 1050px) {
  .sidebar {
    display: block;
    float: left;
    width: 18%;
    height: 100%;
    text-align: right;
    padding-right: 20px;
    margin-top: 30px;
    top: 20px;
  }
}
.sidebar-scroll {
  position: fixed;
  margin-top: 0;
}
.sidebar-nav {
  list-style: none;
  font-size: .85rem;
  margin-top: 5px;
  margin-bottom: 0;
}
.sidebar-nav li a {
  color: #DCD9C8;
  border-bottom: 0;
  transition: .3s color;
}
.sidebar-nav .active a,
.sidebar-nav li a:hover {
  color: #3ba5cf;
  border-bottom: 0;
  transition: .3s color;
}
