.project {
	border: 1px solid #FFF;
}
.project-image {
	background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
	height: 400px;
}
.project img {
  margin-top: 0;
}
