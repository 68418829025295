html {
	font-size: 17px;
}
@media (min-width:768px) {
	html {
		font-size: 19px;
	}
}
body {
  font-size: 1em;
  line-height: 1.65;
  color: $newsprint-dark;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: darken($newsprint-dark, 2%);
    color: $newsprint-mid-light;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-size: 1rem;
  color: #2A594E;
}
h1 {
  font-size: 1.6rem;
}
h2 {
  margin-top: 1.75rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
h4 {
  margin-top: -7px;
  margin-bottom: .5rem;
  font-weight: normal;
}
h5 {
  font-size: 1.25rem;
  font-weight: normal;
  margin-top: 0;
}
p {
	margin-top: 0;
	margin-bottom: 1.2rem;
}
a {
	color: #2A594E;
  transition: .25s opacity;
}
a:hover {
  opacity: .65;
  transition: .25s opacity;
}
@media (prefers-color-scheme: dark) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: #7F9B82;
  }
}
hr {
	border-top: 1px solid rgba($seafoam, 0.5);
  width: 40%;
	border-bottom: none;
	border-left: none;
	margin-top: 3rem;
	margin-bottom: 3rem;
}
ol li,
ul li {
	margin-bottom: .75rem;
}
.basic-list {
  list-style: none;
  padding-left: 0;
}
.list-inline li {
  display: inline-block;
  padding-right: 1.25rem;
}
blockquote {
  font-size: 1rem;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-left: 2px solid $treasure-mid-dark;
}
blockquote p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  p[data-pullquote] {
    display: block;
  }
  p[data-pullquote]:before {
    content: attr(data-pullquote);
    float: right;
    width: 250px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: .5rem;
    margin-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $signal-mid-dark;
    font-size: 1.3rem;
    border-top: 2px solid $seafoam-mid-light;
  }
}
@media(prefers-color-scheme: dark) {
  p[data-pullquote]:before {
    color: $seafoam;
    border-top-color: $seafoam-mid-dark;
  }
}
@media (min-width: 980px) {
  p[data-pullquote]:before {
    margin-right: -5rem;
  }
}
pre {
  background-color: darken($newsprint-dark, 3%);
  padding: .5rem;
  border-radius: 4px;
}
code {
  font-size: .7rem;
  white-space:pre;
  white-space:pre-wrap;
}
figure {
  margin-left: 0;
  margin-right: 0;
}
@media(min-width: 980px) {
  figure {
    margin-left: -50px;
    margin-right: -50px;
  }
}
.caption {
  font-size: .9rem;
  font-style: italic;
}
.footnotes {
	border-top: 1px solid $seafoam-mid-light;
	margin-top: 2rem;
  font-size: .85rem;
}
.small {
  font-size: .5rem;
}
.wf-loading {
	visibility: hidden;
}
.wf-active {
	visibility: visible;
}
