.grid {
  display: grid;
}
.grid-gap-1 {
  grid-gap: 1rem;
}
@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-gap-1 {
    grid-gap: 2rem;
  }
  .grid-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
.container {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
  margin-left: auto;
}
.container-narrow {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 700px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}
.main-content {
  padding-bottom: 20px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row:after {
   content: " ";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}
[class*="col-"] {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33333%;
}
.col-8 {
  width: 75%;
}
.col-12 {
  width: 100%;
}
.mvh-100 {
  max-height: 100vh;
}
