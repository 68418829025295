.section {
  margin-top: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $newsprint-light;
}
@media (prefers-color-scheme: dark) {
  .section {
    background-color: $newsprint-dark;
  }
}
