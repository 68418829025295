$white: #FFF;

$coral-light: #F5DFDC;
$coral-mid-light: #E39B94;
$coral: #CC5649;
$coral-mid-dark: #B33729;
$coral-dark: #7D2015;

$treasure-light: #FFFDDE;
$treasure-mid-light: #F7F3BA;
$treasure: #F2EB82;
$treasure-mid-dark: #D1BB62;
$treasure-dark: #AB924F;

$newsprint-light: #F4F6F6;
$newsprint-mid-light: #C7D1D0;
$newsprint: #A2ABAA;
$newsprint-mid-dark: #686E6D;
$newsprint-dark: #222424;

$signal-light: #DAE9F2;
$signal-mid-light: #7B96B8;
$signal: #173F7C;
$signal-mid-dark: #092959;
$signal-dark: #021229;

$seafoam-light: #DDEDE7;
$seafoam-mid-light: #A0BDB2;
$seafoam: #658C7D;
$seafoam-mid-dark: #40574E;
$seafoam-dark: #060E05;

$animation-timing: .25s;
