@import 'variables';
@import 'diver-variables';
@import 'diver-typography';
@import 'diver-skins';
@import 'global';
@import 'grid';
@import 'layout';
@import 'typography';
@import 'menu';
@import 'sidebar';
@import 'post';
@import 'project';
@import 'gallery';
@import 'form';
@import 'stats';
@import 'blend-modes';

.home-illustration-1 {
  position: relative;
  z-index: 1;
}
.home-illustration-1::before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url('https://res.cloudinary.com/db5mnmxzn/image/upload/v1730471113/IMG_8538_bkg7c9.jpg');
  background-blend-mode: multiply;
  opacity: 0.17;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (prefers-color-scheme: dark) {
  .home-illustration-1::before {
    background-image: url('/img/celestial-atlas.jpg');
    filter: invert(100%);
    opacity: 0.3;
    background-blend-mode: color-dodge;
  }
}
