.post-meta {
	color: #777;
  font-size: .9rem;
}
.post-date {
  text-transform: uppercase;
  font-size: .8rem;
  letter-spacing: 2px;
}
.post-footer {
	padding-bottom: 2rem;
  text-align: center;
}
.post-image {
  margin-top: 0;
  border-radius: 0;
}
