* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
body {
  padding-bottom: 60px;
}
img {
	width: 100%;
	height: auto;
	border-radius: 3px;
  vertical-align: middle;
  margin-top: 20px;
  margin-bottom: 20px;
}
.shape-circle {
	shape-outside: circle(50%);
}
.img-profile {
	margin-top: 0;
	margin-bottom: 0;
}
.sr-only { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; }
.lang-en:lang(en), .lang-fr:lang(fr) {
    font-weight: bold;
}
#spacer-1:before {
	font-size: 22px;
	content: '⁜';
}
#spacer-2:before {
	font-size: 24px;
	content: '⁘';
}
#spacer-3:before {
	content: '∴';
}
