/*
 * Form
 */

form {
	text-align: center;
}
.text-box {
	color: $text-color;
  padding: 12px 20px;
	margin-top: .5rem;
	margin-bottom: 1rem;
	border: 1px solid #E7E7E7;
	border-radius: .25rem;
  width: 100%;
}
