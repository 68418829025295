$white: #FFF;
$main-color: #005a80;
$text-color: #555;
$neutral: #DCD9C8;
$light-grey: #E7E7E7;

.text {
  color: $text-color;
}
.main {
  color: $main-color;
}
.bg-main {
  background-color: $main-color;
}
.b--main {
  border-color: $main-color;
}
